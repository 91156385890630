import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Laborum amet laborum magna incididunt consectetur eiusmod. Enim excepteur laboris non adipisicing cupidatat laboris esse excepteur veniam fugiat. Deserunt irure cillum veniam ullamco et ut Lorem ea sint. Cupidatat occaecat laboris reprehenderit magna est. Officia non tempor proident exercitation ad exercitation adipisicing.`}</p>
    </PageDescription>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      